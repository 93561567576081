import CertificationGroup from "@components/About/CertificationGroup/CertificationGroup";
import { BannerCarousel, CategoriesCarousel } from "@components/Carousel";
import { PartnersCarousel } from "@components/Carousel/PartnersCarousel";
import { ButtonCommonComponent } from "@components/Common/Button";
import { ContentWithImage } from "@components/Common/ContentWithImage";
import { CoverCommonComponent } from "@components/Common/Cover";
import { Spacing } from "@components/Common/Spacing";
import { FactoriesList } from "@components/FactoriesList";
import AboutBillsExchange from "@components/Sections/AboutBillsExchange";
import AboutHome from "@components/Sections/AboutHome";
import AboutWithIconOverImage from "@components/Sections/AboutWithIconOverImage";
import CardsWithIcon from "@components/Sections/CardsWithIcon";
import CondictionInfo from "@components/Sections/CondictionInfo";
import InformativeBlock from "@components/Sections/Contact/InformativeBlock";
import { ContactUs } from "@components/Sections/ContactUs";
import ContentMarkdown from "@components/Sections/Content";
import CorrespondentBank from "@components/Sections/CorrespondentBank";
import Cover from "@components/Sections/Cover";
import CreditOpeningForm from "@components/Sections/CreditOpeningForm";
import DescriptionWithIcon from "@components/Sections/DescriptionWithIcon";
import FAQ from "@components/Sections/FAQ";
import FeesAndDeadline from "@components/Sections/FeesAndDeadline";
import ImportantInformation from "@components/Sections/ImportantInformation";
import InvestimentSimulator from "@components/Sections/InvestmentSimulator";
import LoanSimulator from "@components/Sections/LoanSimulator";
import ModalityList from "@components/Sections/ModalityList";
import NumbersPresentation from "@components/Sections/NumbersPresentation";
import PagePresentation from "@components/Sections/PagePresentation";
import PagePresentationWithTabs from "@components/Sections/PagePresentationWithTabs";
import ProductsList from "@components/Sections/ProductsList";
import Slider from "@components/Sections/Slider";
import StoreListByState from "@components/Sections/StoreListByState";
import { YoutubeSection } from "@components/Sections/Youtube";
import { Separator } from "@components/Separator";

export const sectionComponents = {
  "sections.about-home": AboutHome,
  "sections.cover": Cover,
  "sections.page-presentation": PagePresentation,
  "sections.faq": FAQ,
  "sections.cards-with-icon": CardsWithIcon,
  "sections.important-info": ImportantInformation,
  "sections.condition-frame": CondictionInfo,
  "sections.separator": Separator,
  "sections.modality-list": ModalityList,
  "sections.description-with-icon": DescriptionWithIcon,
  "sections.correspondent-bank": CorrespondentBank,
  "sections.credit-opening-form": CreditOpeningForm,
  "sections.about-bills-exchange": AboutBillsExchange,
  "sections.about-with-icon-over-image": AboutWithIconOverImage,
  "sections.loan-simulator": LoanSimulator,
  "sections.fees-and-deadlines": FeesAndDeadline,
  "sections.page-presentation-with-tabs": PagePresentationWithTabs,
  "sections.informative-block": InformativeBlock,
  "sections.investiment-simulator": InvestimentSimulator,
  "sections.number-presentation": NumbersPresentation,
  "sections.contact": ContactUs,
  "sections.slider": Slider,
  "sections.content": ContentMarkdown,
  "sections.youtube": YoutubeSection,
  "sections.product-list": ProductsList,
  "sections.stores": StoreListByState,
  "carousels.banner-carousel": BannerCarousel,
  "carousels.categories-carousel": CategoriesCarousel,
  "carousels.partners-carousel": PartnersCarousel,
  "common.factories": FactoriesList,
  "common.spacing": Spacing,
  "common.button": ButtonCommonComponent,
  "common.cover": CoverCommonComponent,
  "common.certification-group": CertificationGroup,
  "common.content-with-image": ContentWithImage,
};