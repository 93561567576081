import { HStack, IconButton, Input, Text } from "@chakra-ui/react";
import { ArrowLeftSimpleIcon, ArrowRightSimpleIcon } from "@src/icons";
import { Dispatch, FormEvent, memo, SetStateAction, useEffect, useState } from "react";

export interface PaginationData {
  currentPage: number;
  pagesQuantity?: number;
}

interface PaginationProps extends PaginationData {
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const FIRST_PAGE = 1;

const Pagination = ({ currentPage, pagesQuantity, setCurrentPage }: PaginationProps) => {
  const [page, setPage] = useState<number>(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const previousPage = () => setSelectedPage(page - 1);

  const nextPage = () => setSelectedPage(page + 1);

  const handleOnInputEvent = (event: FormEvent<HTMLInputElement>) => {
    const inputValue = Math.abs(Number(event.currentTarget.value));

    setPage(inputValue);
  };

  const handleOnBlurEvent = (event: FormEvent<HTMLInputElement>) => {
    setSelectedPage(Number(event.currentTarget.value));
  };

  const setSelectedPage = (page: number) => {
    setCurrentPage(verifyInputValue(page) ? page : FIRST_PAGE);
  };

  const verifyInputValue = (inputValue?: number) => {
    const totalOfPages = pagesQuantity || 0;

    return inputValue && inputValue > 0 && inputValue <= totalOfPages;
  };

  return (
    <HStack width="100%" justifyContent={{ base: "space-between", md: "center" }} marginTop="0 !important">
      <IconButton
        aria-label="Página anterior"
        icon={<ArrowLeftSimpleIcon color="primary.500" />}
        isDisabled={currentPage === FIRST_PAGE}
        onClick={previousPage}
      />
      <Text>Página </Text>
      <Input
        type="number"
        height="40px"
        width="60px"
        value={page}
        onBlur={handleOnBlurEvent}
        onInput={handleOnInputEvent}
      />{" "}
      <Text>de {pagesQuantity}</Text>
      <IconButton
        aria-label="Próxima página"
        icon={<ArrowRightSimpleIcon color="primary.500" />}
        isDisabled={currentPage === pagesQuantity}
        onClick={nextPage}
      />
    </HStack>
  );
};

export default memo(Pagination);
