import { Icon, IconProps } from "@chakra-ui/react";

export const WaveIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 1095 369" preserveAspectRatio="xMidYMid meet" fill="none" {...(props as any)}>
      <path
        opacity="0.88"
        d="M586.831 15.4513C145.218 81.9558 137.775 11.018 0 15.4512V264.818V369H1094.85C1094.85 183.251 1118.27 -64.5797 586.831 15.4513Z"
        fill="currentColor"
      />
    </Icon>
  );
};
