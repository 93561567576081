import { Button, Text } from "@chakra-ui/react";
import { Dispatch, memo, SetStateAction } from "react";

export interface StateButtonProps {
  onClick: Dispatch<SetStateAction<string | undefined>>;
  selected?: boolean;
  initials?: string;
  name: string;
}

const StateButton = ({ initials, name, onClick, selected }: StateButtonProps) => {
  const handleOnClickEvent = () => {
    onClick(initials);
  };

  return (
    <Button minWidth="auto" variant={selected ? "solid" : "outline"} colorScheme="primary" onClick={handleOnClickEvent}>
      <Text minWidth="full" fontWeight={selected ? 600 : 400} fontSize={{ base: "0.8rem", md: "1.125rem" }}>
        {name}
      </Text>
    </Button>
  );
};

export default memo(StateButton);
