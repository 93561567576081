import { Box } from "@chakra-ui/react";
import { Container } from "@components/Container";
import useWindowSize from "@src/hooks/useWindowSize";
import { WaveIcon } from "@src/icons/wave";
import { StrapiCover } from "@src/types/strapi";
import Image from "next/image";
import { useRouter } from "next/router";
import Markdown from "../../Markdown";
import { ButtonCommonComponent } from "../Button";
import { colorDescription } from "./utils";

const getTitleBoxStyles = (props: StrapiCover) => {
  if (!props.wave) {
    return { maxWidth: "580px" };
  }

  const paddingDirection = props.flip ? "paddingRight" : "paddingLeft";

  return {
    [paddingDirection]: { lg: "5%", xl: "15%", "2xl": "0" },
    paddingTop: { lg: "28%", xl: "20%", "2xl": "15%" },
    maxWidth: { lg: "650px", xl: "735px", "2xl": "650px" },
  };
};

const RenderCoverTitle = (props: StrapiCover) => {
  if (!props.description) {
    return null;
  }

  return (
    <Box
      {...getTitleBoxStyles(props)}
      color={colorDescription[props.descriptionColor]}
      textAlign={{
        base: "center",
        md: props.flip ? "right" : "left",
      }}
    >
      <Markdown>{props.description}</Markdown>
    </Box>
  );
};

const RenderCoverButton = (props: StrapiCover) => {
  if (!props.button) {
    return null;
  }

  const paddingDirection = props.flip ? "paddingRight" : "paddingLeft";
  const style = { [paddingDirection]: { lg: "5%", xl: "15%", "2xl": "0" } };

  return (
    <Box {...style}>
      <ButtonCommonComponent {...props.button} renderWithContainer={false} />
    </Box>
  );
};

const RenderCoverWave = (props: StrapiCover) => {
  if (!props.wave) {
    return null;
  }

  return (
    <WaveIcon
      display={{ base: "none", lg: "inline-block" }}
      color={"primary.400"}
      maxHeight={{ base: "369px", "2xl": "full" }}
      height={"auto"}
      width={"full"}
      maxWidth={{ base: "full", lg: "80%", xl: "850px", "2xl": "58%" }}
      position={"absolute"}
      bottom={"0"}
      left={!props.flip ? "0" : undefined}
      right={props.flip ? "0" : undefined}
      transform={props.flip ? "scaleX(-1)" : undefined}
    />
  );
};

export const CoverCommonComponent = (props: StrapiCover) => {
  const router = useRouter();
  const { isMobile } = useWindowSize();
  const img = isMobile ? props.image.mobile_image : props.image.desktop_image;

  const handleCoverClick = () => {
    if (!props.link) return;

    if (props.isExternal) {
      window.open(props.link, "_blank", "noopener noreferrer");
      return;
    }

    router.push(props.link);
  };

  return (
    <Box position="relative" onClick={handleCoverClick} cursor={props.link ? "pointer" : "default"}>
      <Box width="full" height={{ base: "400px", lg: img.height }}>
        <Image src={img.url} alt={img.name} sizes="100vw" layout="fill" />
      </Box>
      <RenderCoverWave {...props} />
      <Container position="absolute" height={"100%"} top="0px" justifyContent="flex-start">
        <Container
          position={"relative"}
          flexDirection="column"
          alignItems={props.flip ? "flex-end" : "flex-start"}
          gridGap={{ base: "1.5rem", md: "2rem", lg: "2.625rem" }}
        >
          <RenderCoverTitle {...props} />
          <RenderCoverButton {...props} />
        </Container>
      </Container>
    </Box>
  );
};
