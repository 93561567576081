import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { ImageCommonComponent } from "@components/Common/Image";
import { Container } from "@components/Container";
import Markdown from "@components/Markdown";
import { StrapiContact } from "@src/types/strapi";
import { PropsWithChildren } from "react";
import { ContactList } from "./ContactUsList";

export const ContactUsContainer = (props: PropsWithChildren<StrapiContact>) => {
  return (
    <Flex
      flexDirection={"column"}
      width={"full"}
      position={"relative"}
      marginBottom={{
        base: "2rem",
        lg: "4rem",
        xl: "6rem",
      }}
    >
      <Box width={"full"} minHeight={"23.5rem"} position={"relative"}>
        <ImageCommonComponent {...props.contactBanner} />
        <Text
          position={"absolute"}
          left={{ base: "1.8125rem", xl: "3%", "2xl": "17.2%" }}
          top={{ base: "35%" }}
          fontSize={{
            base: "4rem",
            md: "5rem",
            lg: "4rem",
            xl: "3.625rem",
          }}
          fontWeight={"700"}
          color={"primary.500"}
        >
          {props.bannerTitle}
        </Text>
      </Box>
      <Container
        justifyContent={"flex-start"}
        paddingTop={{
          base: "1rem",
          xl: "2rem",
        }}
      >
        <VStack
          flex={1}
          alignItems={"flex-start"}
          gridGap={{ base: "0.5rem", lg: "2rem" }}
          maxWidth={{ base: "full", xl: "29.5rem" }}
        >
          <Heading
            as={"h1"}
            fontWeight={"600"}
            maxWidth={{
              base: "full",
              xl: "18.75rem",
            }}
            fontSize={{
              base: "3rem",
              xl: "2.375rem",
            }}
          >
            {props.title}
          </Heading>
          <VStack
            gridGap={{ base: "0.5rem", lg: "1.5rem" }}
            alignItems={"flex-start"}
          >
            <Heading
              as={"h3"}
              color={"primary.600"}
              fontSize={{
                base: "1.5rem",
                md: "1.375rem",
              }}
              fontWeight={"600"}
              maxWidth={{
                base: "full",
                xl: "27.625rem",
              }}
            >
              {props.subTitle}
            </Heading>
            <Markdown>{props.description}</Markdown>
          </VStack>
          <ContactList items={props.contactItem} />
          <Markdown>{props.openingHours}</Markdown>
        </VStack>
      </Container>
      <Flex
        paddingX={{ base: "1.8125rem", xl: 0 }}
        width={"auto"}
        height={"auto"}
        position={{ base: "initial", xl: "absolute" }}
        top={{ base: "10%", "2xl": "12%" }}
        right={{ base: "1.8125rem", "2xl": "16%" }}
        marginTop={{ base: "0.5rem", md: "1rem", xl: "0" }}
      >
        <Flex
          flexShrink={0}
          width={{ base: "full", xl: "47.5rem" }}
          maxHeight={{ base: "auto", xl: "480px" }}
          backgroundColor={{ base: "none", md: "rgba(0, 135, 57, 0.8)" }}
          backgroundBlendMode={"multiply"}
          borderTopLeftRadius={"0.5rem"}
          borderTopRightRadius={"0.5rem"}
          padding={{ base: "0", md: "1rem", xl: "2rem" }}
        >
          <Flex
            flex={1}
            justifyContent={"center"}
            backgroundColor={"white"}
            borderRadius={"0.375rem"}
            padding={{ base: "1rem", xl: "2rem" }}
            paddingTop={{ base: "1rem", xl: "2.4375rem" }}
            boxShadow={{
              base: "none",
              xl: "0px 24px 32px -12px rgba(0, 0, 0, 0.08)",
            }}
            borderWidth={"0.5px"}
            height={"fit-content"}
            borderColor={"gray.300"}
          >
            {props.children}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
