import { Spinner } from "@chakra-ui/core";
import * as Sentry from "@sentry/nextjs";
import { sectionComponents } from "@src/config/components";
import { getLayoutDefaultProps, LayoutDefault } from "@src/layouts";
import {
  ICookiesConsent,
  IFooter,
  IFraudConsent,
  IGenericComponent,
  IMenuData,
  IPageData,
  IPrivacyPolicyProps,
} from "interfaces/Home.interface";
import { GetServerSidePropsResult } from "next";
import { useRouter } from "next/router";
import React from "react";
import { api } from "../service/api";
import styles from "../styles/Home.module.css";

interface IHome {
  menuData: IMenuData;
  pageData: IPageData;
  footerData: IFooter;
  cookiesData: ICookiesConsent;
  privacyPolicyData: IPrivacyPolicyProps;
  fraudData: IFraudConsent;
  privacyPolicyPageData: IPrivacyPolicyProps;
}

const Home: React.FC<IHome> = ({ pageData, ...props }) => {
  const router = useRouter();

  if (router.isFallback) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner color={"blue"} size={"xl"} />
      </div>
    );
  }

  const { title, description, items, key } = pageData;

  const renderComponent = (item: IGenericComponent) => {
    const Comp = sectionComponents[item.__component];
    return Comp ? <Comp key={`${item.__component}-${item.id}`} {...item} /> : null;
  };

  return (
    <main>
      <LayoutDefault pageKey={key} title={title} description={description} {...props}>
        {items.map(renderComponent)}
      </LayoutDefault>
    </main>
  );
};

export default Home;

export async function getServerSideProps(context): Promise<GetServerSidePropsResult<any>> {
  const { slug } = context.params;
  try {
    const layoutProps = await getLayoutDefaultProps();
    const { data: pageData } = await api.get(slug ? `/pages/key/${slug}` : "/pages/key/home");

    return {
      props: {
        pageData,
        ...layoutProps,
      },
    };
  } catch (ex) {
    Sentry.captureException(ex);
    return {
      notFound: true,
    };
  }
}
